import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import Vuelidate from "vuelidate";
import JsonExcel from "vue-json-excel";

import i18n from "@/libs/i18n";
import router from "./router";
import store from "./store";
import App from "./App.vue";
import swal from "sweetalert2";
window.Swal = swal;

import VueCountdown from "@chenfengyuan/vue-countdown";
Vue.component(VueCountdown.name, VueCountdown);

// Global Components
import "./global-components";

// 3rd party plugins
import "@axios";
import "@/libs/acl";
import "@/libs/portal-vue";
import "@/libs/clipboard";
import "@/libs/toastification";
import "@/libs/sweet-alerts";
import "@/libs/vue-select";
import "@/libs/tour";

// Axios Mock Adapter
import "@/@fake-db/db";

import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs

Vue.use(LottieAnimation); // add lottie-animation to your global scope
Vue.component("downloadExcel", JsonExcel);

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);
Vue.use(Vuelidate);
Vue.use(VueTelInput, {
  disabledFetchingCountry: false,
  dropdownOptions: {
    disabled: true,
  },
  defaultCountry: 92,
  validCharactersOnly: true,
});

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require("@core/assets/fonts/feather/iconfont.css"); // For form-wizard

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
